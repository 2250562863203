import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom"
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    DotGroup,
  } from "pure-react-carousel"
import ArrowIcon from '../ArrowIcon'
import Trans from '../Trans'

const ClientsFeedbackCarousel = () => {

    const [slideVideoDetails, setslideVideoDetails] = useState([
        {
            slideNo: 0,
            media: `<div id='embed-container'>
                        <iframe width="503" height="328" src="https://www.youtube.com/embed/TZwFECJYHrc?si=aToDADGvQXOzDsRZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div> `,
            thumbnail: `2024/05/vid-thumbnail01.webp`,
            quote: `We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SB-1-1.webp`,
            author: `Shruthi Bhopiah`,
            designation: `AVP and Head of Internal Communication`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Infosys.webp`,
            truncateText: true,
            showThumbnail: true
        },
        {
            slideNo: 1,
            media: `<div id='embed-container'>
                        <iframe width="503" height="328" src="https://www.youtube.com/embed/lHY-wfGV_tU?si=I7Dd5Qt8jtD21IZH" title="The Adecco Group - Testimonial Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>`,
            thumbnail: `2024/05/vid-thumbnail02.webp`,
            quote: `We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/BS.webp`,
            author: `Bani Sodhi`,
            designation: `Human Resources`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Adecco.webp`,
            truncateText: true,
            showThumbnail: true
        },
    ])

    const [slideImageDetails, setslideImageDetails] = useState([
        {
            slideNo: 2,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Textile.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Textile" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Textile.webp" type="image/webp" alt="VC-Textile" />
                        </picture>
                    </div>`,
            quote: `Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/RA.webp`,
            author: `Roshan Andrews`,
            designation: `HR, Employee Experience & Internal Communications`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Arvind.webp`,
            truncateText: true
        },
        {
            slideNo: 3,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Real-estate.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Real-estate" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Real-estate.webp" type="image/webp" alt="VC-Real-estate" />
                        </picture>
                    </div>`,
            quote: `The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/NK.webp`,
            author: `Nikita Kamtekar`,
            designation: `Internal Communications Manager`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatarealty.webp`,
            truncateText: true
        },
        {
            slideNo: 4,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Consultant.webp" media='(min-width: 640px)' type="image/webp" alt="VC-Consultant" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Consultant.webp" type="image/webp" alt="VC-Consultant" />
                        </picture>
                    </div>`,
            quote: `Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/AF.webp`,
            author: `Aiman Fatima`,
            designation: `Employee Relations`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Merilytics.webp`,
            truncateText: true
        },
        {
            slideNo: 5,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" media='(min-width: 640px)' type="image/webp" alt="VC-IT" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" type="image/webp" alt="VC-IT" />
                        </picture>
                    </div>`,
            quote: `Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SG.webp`,
            author: `Samir Gadgil`,
            designation: `Vice President and HR Head`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Wipro.webp`,
            truncateText: true
        },
        {
            slideNo: 6,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-education.webp" media='(min-width: 640px)' type="image/webp" alt="VC-education" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-education.webp" type="image/webp" alt="VC-education" />
                        </picture>
                    </div>`,
            quote: `Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/RJ.webp`,
            author: `Ritesh Joseph`,
            designation: `HR Business Partner`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/ischoolconnect.webp`,
            truncateText: true
        },
        {
            slideNo: 7,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/PG.webp`,
            author: `Pankaj Gupta`,
            designation: `Associate Manager`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/TAdigital.webp`,
            truncateText: true
        },
        {
            slideNo: 8,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-fintech-solutions.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-fintech-solutions.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Shiney Xavier`,
            designation: `Associate Manager- HR, Voya India (Formerly VFI SLK)`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/voyaindia-logo.png`,
            truncateText: true
        },
        {
            slideNo: 9,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Business-management.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Business-management.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SK.webp`,
            author: `Swati Kohli`,
            designation: `Lead HR`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/escalon.webp`,
            truncateText: true
        },
        {
            slideNo: 10,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-electric-power.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-electric-power.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Vantage Circle Platform was implemented to support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/VM.webp`,
            author: `Vimmi Mehta`,
            designation: `Head – R&R & Quality Improvement`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatapower.webp`,
            truncateText: true
        },
        {
            slideNo: 11,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-automobile.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-automobile.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Building a highly engaged workforce is integral to Tata Motors' vision. One of the important pillars for fostering engagement is to celebrate contributions and milestones. This can be best achieved by real-time recognition and celebration. Vantage Circle has been a wonderful partner as we developed our Digital Rewards & Recognition program - Accolades. We value our collaboration with Team Vantage and look forward to working together on offering great experience to our employees.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/NS.webp`,
            author: `Nitin Sansare`,
            designation: `Head – Performance Management, Compensation & Benefits`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Tatamotors.webp`,
            truncateText: true
        },
        {
            slideNo: 12,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-IT.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Implementing Vantage Perks as our employee benefits platform has been a game-changer for our organization. With exclusive offers from leading brands and cashback points on purchases, our employees have found it effortless to utilize the service provided. Moreover, the chosen employee benefits have been customized as per our requirements. Our employees absolutely love it, making them happier and more engaged. We couldn't be more glad for our association with Vantage Perks. I would highly recommend Vantage Perks as it offers a great redemption method and features products aligned with our employees' interests. Taking part in this initiative encourages employees to contribute more to the workplace.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Poorna Sridharan`,
            designation: `HR Executive`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Happiest-Minds.png`,
            truncateText: true
        },
        {
            slideNo: 13,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-BPO.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-BPO.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `We'd like to express our satisfaction with the seamless implementation of Vantage Circle's Recognition Platform. Working with Vantage Circle's team has been an absolute breeze, making it one of the smoothest implementations we've ever undertaken. From day one, their team has been incredibly supportive, addressing all our inquiries and providing guidance at every step of the journey. We met our project timeline and had everything running within just one month. The entire process was incredibly smooth and hassle-free. It was truly an amazing experience!`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Eloisa-sanz.png`,
            author: `Eloisa Sanz`,
            designation: `VP Human Resources, Transformation Projects`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/YNV.png`,
            truncateText: true
        },
        {
            slideNo: 14,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-automative-electrification.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-automative-electrification.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `The need for digitalization, real-time recognition, and access to a wide range of vouchers drove our search for a rewards and recognition platform like Vantage Rewards. What set the platform apart were its customization options, the points validity feature, the user-friendly interface, and the innovative use of hashtags. Our objectives with Vantage Rewards include enhancing employee participation and leveraging analytics for insights. The implementation process has been exceptionally smooth, thanks to great support from the Vantage team, who helped tailor the platform to our specific needs. Their support team was impressively responsive and quick to address any challenges. Based on our experience, we wholeheartedly recommend Vantage Rewards to other organizations. Its vast features, adaptable user interface, and flexibility to meet unique business needs make it an invaluable tool for organizations in a similar position.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Bhargavi-P.png`,
            author: `Bhargavi P Sirdeshpande`,
            designation: `Manager HR`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/VItesco.png`,
            truncateText: true
        },
        {
            slideNo: 15,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/DNV-COver.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/DNV-COver.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Seeking a flexible R&R platform accommodating our diverse workforce, Vantage Rewards emerged as the ideal choice. Its user-friendly interface, customizable features, and array of face-value reward options surpassed competitors. With the goal of enhancing engagement and productivity, Vantage Rewards seamlessly integrated despite minor site-specific challenges. The support team’s promptness and expertise were commendable. We anticipate heightened morale, improved team cohesion, and enhanced KPIs in engagement and performance. Its alignment with our culture makes it recommended for organizations seeking a robust R&R solution.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Jade-Ding.png`,
            author: `Jade Ding`,
            designation: ` Regional HR Advisor, South Asia, People & Talent`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/DNV-logo.png`,
            truncateText: true
        },
        {
            slideNo: 16,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Office-equipment.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Office-equipment.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Vantage Circle has been great for us. They met our expectations in every aspect. The pulse tool, a unique feature of their platform, was a massive advantage, and their ability to customize the tool to our needs was exceptional. We appreciated that their updates didn't disrupt our customizations. The sales team was outstanding, mapping out precisely how the tool would benefit our recognition program and enhance our company culture. The implementation process was seamless, thanks to their upfront communication and dedication. Within just 90 days of signing the contract, we were up and running. Vantage Circle's commitment to our success was a pleasant surprise, and their quick response to our customization needs was impressive. It has truly been a transformative experience for our company, embraced by every generation within our organization.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Company Representative`,
            designation: ``,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Dex-imaging.png`,
            truncateText: true
        },
        {
            slideNo: 17,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/Medplus-cover.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VMedplus-cover.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `The experience with the Vantage Circle team has been exceptional. The implementation process proceeded seamlessly, adhering to our agreed-upon timelines. We greatly appreciated the multilingual support provided, which facilitated the entire implementation journey. The Vantage Circle Customer Success team has consistently demonstrated great support and responsiveness. The Customer Success team is always available to help and responds to our requests as quickly as possible. We are very grateful to everyone who has collaborated in our launch and for their support throughout the process. Thanks Vantage, for being a platform that meets our needs. Vantage complies with the support and incorporates two languages and two currencies at the same time. This support covers our population in Puerto Rico and Colombia. Since the launch, we have witnessed a noteworthy increase in employee engagement. Vantage Circle's product and its features are intuitive, making it effortless for our team to grasp and implement. Thanks to Vantage Circle's product, we now feel positioned to enhance our employee experience significantly. We are happy with Vantage Circle team and platform.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Company Representative`,
            designation: ``,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/Medplus.png`,
            truncateText: true
        },
        {
            slideNo: 18,
            media: `<div id='embed-container'>
                        <picture>
                            <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Office-equipment.webp" media='(min-width: 640px)' type="image/webp" alt="" width="" />
                            <img src="https://res.cloudinary.com/vantagecircle/image/upload/w_1200/q_auto/gatsbycms/uploads/2024/01/VC-Office-equipment.webp" type="image/webp" alt="" />
                        </picture>
                    </div>`,
            quote: `Vantage R&R revolutionized recognition at VFI-SLK. While our prior methods were decent, their automation and diverse rewards transformed our approach. Before, we had limited options for recognizing employees, but the current automated system provides various categories for rewarding the team. Implementing Vantage R&R has proven to be useful and seamlessly integrated into our workflow, making the entire process effortless. Although I'm still exploring features, the initial experience is promising. While specific metrics aren't quantified, the potential for positive impact is evident. I wholeheartedly recommend Vantage R&R for its user-friendly, efficient, and diverse staff recognition and rewards approach.`,
            authorImage: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/default-avatar.webp`,
            author: `Vineeth R.,`,
            designation: `HR, Voya India (Formerly VFI SLK)`,
            logo: `https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/01/voyaindia-logo.png`,
            truncateText: true
        },
    ])

    const handleVideoTruncateClass = (slideNo) =>{
        setslideVideoDetails(slideVideoDetails => {
            return slideVideoDetails.map(slide => {
                if(slide.slideNo == slideNo){
                    return {...slide, truncateText: false}
                }
                return slide
            })
        })
    }

    const handleImageTruncateClass = (slideNo) =>{
        setslideImageDetails(slideImageDetails => {
            return slideImageDetails.map(slide => {
                if(slide.slideNo == slideNo){
                    return {...slide, truncateText: false}
                }
                return slide
            })
        })
    }

    const handleThumbnail = (slideNo) => {
        setslideVideoDetails(slideVideoDetails => {      
            return slideVideoDetails.map((slide) => {
                if (slide.slideNo === slideNo) {
                    return { ...slide, showThumbnail: false };
                }
                return slide;
        })
    })  
    };
    

  return (
    // <CarouselProvider
    // className="max-w-7xl px-2 relative mx-auto max-h-1500 h-auto"
    // naturalSlideWidth={100}
    // naturalSlideHeight={100}
    // totalSlides={2}
    // isPlaying={false}
    // interval={4000}
    // >

    <CarouselProvider
        className="max-w-7xl px-2 relative mx-auto max-h-1500 h-auto xl:h-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={19}
        isPlaying={false}
        interval={4000}
        infinite= {true}
      >
        <Slider className="rounded-xl bg-white lg:shadow-xl z-0 grid items-start lg:items-center h-1000 md:h-1000 lg:max-h-365px lg:h-auto">
            {
                slideVideoDetails?.map((eachSlide) => (
                    <Slide index={eachSlide.slideNo} className= "">
                        <div className = "lg:flex bg-white rounded-xl p-5 items-center">
                            <div className='lg:flex'>
                                <div className='w-full h-full lg:w-5/12 overflow-hidden rounded-xl'>
                                    <div className={eachSlide.showThumbnail? `block` : `hidden`} onClick={() => handleThumbnail(eachSlide.slideNo)}>
                                        <picture>
                                            <source
                                                className="z-10 relative"
                                                media="(min-width: 1025px)"
                                                srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_650/q_auto/gatsbycms/uploads/${eachSlide.thumbnail}`}
                                                type="image/webp"
                                                alt="Vantage Rewards"
                                                width="503"
                                            />
                                            <source
                                                className="z-10 relative"
                                                srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/${eachSlide.thumbnail}`}
                                                type="image/webp"
                                                alt="Vantage Rewards"
                                                width="503"
                                            />
                                            <img
                                                className="z-10 relative mx-auto lg:mx-0"
                                                src={`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/${eachSlide.thumbnail}`}
                                                alt="Vantage Rewards"
                                                width="503"
                                                loading= "lazy"
                                            />
                                        </picture>
                                    </div>
                                    <div className={eachSlide.showThumbnail? `hidden` : `block`}>
                                        <div dangerouslySetInnerHTML={{ __html: eachSlide.media }} />
                                    </div>                           
                                </div>
                                <div className='w-full lg:w-7/12 pt-7 lg:pt-0 xl:pt-3 lg:p-3 lg:pl-7 xl:pl-10 bg-white'>
                                    <div className={eachSlide.truncateText ? 'pr-3 lg:pr-0 paraHeight hidden lg:block' : 'pr-3 lg:pr-0 customScrollBar-1 paraHeight hidden lg:block'} style= {{ overflowX: 'hidden' }}>
                                        <p className={eachSlide.truncateText ? 'text-gray-500 truncate-text overflow-hidden mb-1' : 'text-gray-500 show-truncated-text overflow-hidden'}>
                                            <Trans>{eachSlide.quote}</Trans>
                                        </p><span className={eachSlide.truncateText ? 'hidden lg:flex text-orange cursor-pointer' : 'hidden'} onClick={() => handleVideoTruncateClass(eachSlide.slideNo)}> Read more</span>
                                    </div>
                                    <div className='lg:hidden paraHeight customScrollBar-1 pr-3 overflow-x-hidden'>
                                        <p className='text-gray-500 show-truncated-text overflow-hidden'>
                                            <Trans>{eachSlide.quote}</Trans>
                                        </p>
                                    </div>
                                    <div className='lg:flex pt-5 lg:pt-5 bg-white'>
                                        <div className=''>
                                            <img class="rounded-full" src={eachSlide.authorImage} height="60" width="60" alt="speaker name" />
                                        </div>
                                        <div className='lg:pl-5'>
                                            <h5>{eachSlide.author}</h5>
                                            <p className='mb-2 text-base'><Trans>{eachSlide.designation}</Trans></p>
                                            <img src={eachSlide.logo} width="90" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                ))
            }

            {
                slideImageDetails?.map((eachSlide) => (
                    <Slide index={eachSlide.slideNo} className= "">
                        <div className = "lg:flex bg-white rounded-xl p-5 items-center">
                            <div className='lg:flex'>
                                <div className='w-full h-full lg:w-5/12 overflow-hidden rounded-xl'>
                                    <div dangerouslySetInnerHTML={{ __html: eachSlide.media }} />                                
                                </div>
                                <div className='w-full lg:w-7/12 pt-7 lg:pt-0 xl:pt-3 lg:p-3 lg:pl-7 xl:pl-10 bg-white'>
                                    <div className={eachSlide.truncateText ? 'pr-3 lg:pr-0 paraHeight hidden lg:block' : 'pr-3 lg:pr-0 customScrollBar-1 paraHeight hidden lg:block'} style= {{ overflowX: 'hidden' }}>
                                        <p className={eachSlide.truncateText ? 'text-gray-500 truncate-text overflow-hidden mb-1' : 'text-gray-500 show-truncated-text overflow-hidden'}>
                                            <Trans>{eachSlide.quote}</Trans>
                                        </p><span className={eachSlide.truncateText ? 'hidden lg:flex text-orange cursor-pointer' : 'hidden'} onClick={() => handleImageTruncateClass(eachSlide.slideNo)}> Read more</span>
                                    </div>
                                    <div className='lg:hidden paraHeight customScrollBar-1 pr-3 overflow-x-hidden'>
                                        <p className='text-gray-500 show-truncated-text overflow-hidden'>
                                            <Trans>{eachSlide.quote}</Trans>
                                        </p>
                                    </div>
                                    <div className='lg:flex pt-5 lg:pt-5 bg-white'>
                                        <div className=''>
                                            <img class="rounded-full" src={eachSlide.authorImage} height="60" width="60" alt="speaker name" />
                                        </div>
                                        <div className='lg:pl-5'>
                                            <h5>{eachSlide.author}</h5>
                                            <p className='mb-2 text-base'><Trans>{eachSlide.designation}</Trans></p>
                                            <img src={eachSlide.logo} width="90" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                ))
            }
            
        </Slider>
        <div className='flex justify-center'>
        <ButtonBack className="shadow-lightgray mx-1 xl:mx-0 mt-4 xl:mt-0 eb-carousel-btn xl:absolute bottom-1/2 left-2 transform xl:-translate-x-8 rotate-180 bg-white p-3 md:p-4 rounded-full">
            <ArrowIcon />
        </ButtonBack>
        <ButtonNext className="shadow-lightgray mx-1 xl:mx-0 mt-4 xl:mt-0 eb-carousel-btn xl:absolute bottom-1/2 right-2 transform xl:translate-x-8 bg-white p-3 md:p-4 rounded-full">
            <ArrowIcon />
        </ButtonNext>
        </div>
       
        <div className="w-full xl:w-4/12 mx-auto flex justify-center py-5 xl:justify-start items-center">
          {/* <DotGroup className="w-full py-5" id="dots" /> */}
        </div>
    </CarouselProvider>
  )
}

const renderClientsFeedbackCarousel = () => {
    if (typeof window !== "undefined") {
      let id = document.querySelector("#clientsFeedback")
      if (id != null) {
        ReactDOM.render(<ClientsFeedbackCarousel />, id)
      }
    }
  }
  
  setTimeout(renderClientsFeedbackCarousel, 100)

export default ClientsFeedbackCarousel